export let productObject = {
  name: '',
  description: '',
  labelsId: [],
  placeOfOriginId: [],
  supplierId: null,
  measurementUnitId: null,
  baseUnit: '1',
  ivaId: null,
  onlineSale: true,
  productType: 'PHY',
  categoriesId: [],
  additionalInformation: [],
  quickSelectionAdd: [],
  video: {
    id: null,
    video: null,
  },
  videoLink: null,
  showVideo: false,
  showVideoLink: false,
  videoAdd: null,
  newVideo: true,
  productDetailAdd: [
    {
      name: 'Producto',
      productCode: '',
      sku: '',
      productionCost: '',
      price: '',
      wholesalePrice: '',
      wholesaleQuantity: '',
      minBuyQuantity: '',
      productDetailVariantValueAdd: [],
      newReleases: false,
      mainPage: false,
      bestSeller: false,
      available: true,
      visible: true,
      photos: {
        '0': {
          id: null,
          photo: null,
          photoCompress: null,
        },
        '1': {
          id: null,
          photo: null,
          photoCompress: null,
        },
        '2': {
          id: null,
          photo: null,
          photoCompress: null,
        },
        '3': {
          id: null,
          photo: null,
          photoCompress: null,
        },
        '4': {
          id: null,
          photo: null,
          photoCompress: null,
        },
      },
      photosAdd: [],
      mainVariant: true,
      shop: 0,
      promotionDetailPromotionAdd: {
        hasPromotionType: false,
        promotionType: '',
        discountValue: '',
        hasPromotionQuantity: false,
        promotionQuantityTake: '',
        promotionQuantityBuy: '',
        promotionPerPrice: '',
        promotionMonPrice: '',
      },
      openCard: true,
      saved: false,
      warningSku: false,
      warningCode: false,
      currentFoto: null,
      digitalProduct: null,
      allowPurchaseWithoutStock: false,
    },
  ],
  variantList: [],
  hasVariants: false,
}

export let productDetalObj = {
  name: '',
  productCode: '',
  sku: '',
  productionCost: '',
  price: '',
  wholesalePrice: '',
  wholesaleQuantity: '',
  minBuyQuantity: '',
  productDetailVariantValueAdd: [],
  newReleases: false,
  mainPage: false,
  bestSeller: false,
  available: true,
  visible: true,
  photos: {
    '0': {
      id: null,
      photo: null,
      photoCompress: null,
    },
    '1': {
      id: null,
      photo: null,
      photoCompress: null,
    },
    '2': {
      id: null,
      photo: null,
      photoCompress: null,
    },
    '3': {
      id: null,
      photo: null,
      photoCompress: null,
    },
    '4': {
      id: null,
      photo: null,
      photoCompress: null,
    },
  },
  photosAdd: [],
  mainVariant: false,
  shop: 0,
  promotionDetailPromotionAdd: {
    hasPromotionType: false,
    promotionType: '',
    discountValue: '',
    hasPromotionQuantity: false,
    promotionQuantityTake: '',
    promotionQuantityBuy: '',
    promotionPerPrice: '',
    promotionMonPrice: '',
  },
  openCard: false,
  saved: false,
  warningSku: false,
  warningCode: false,
  currentFoto: null,
  digitalProduct: null,
  allowPurchaseWithoutStock: false,

}
