export default {
  setCategoriesList: (state, categoriesList) => {
    state.categoriesList = categoriesList
  },
  setTotalCategoriesPages: (state, totalCategoriesPages) => {
    state.totalCategoriesPages = totalCategoriesPages
  },
  setCategoriesTree: (state, categoriesTree) => {
    state.categoriesTree = categoriesTree
  },
  setSubcategoriesList: (state, subcategoriesList) => {
    state.subcategoriesList = subcategoriesList
  },
  setTotalSubcategoriesPages: (state, totalSubcategoriesPages) => {
    state.totalSubcategoriesPages = totalSubcategoriesPages
  },
}
