export default {
  /**
   * Configura las citas del paciente que ya se concretaron.
   */
  setLotList: (state, lotList) => {
    state.lotList = lotList
  },
  setTotalLotsPages: (state, totalLotsPages) => {
    state.totalLotsPages = totalLotsPages
  },
}
