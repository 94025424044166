const shopSuppliers = () =>
  import(/* webpackChunkName: "shop" */ '@/modules/shop/views/ShopSuppliers')
const shopStaff = () =>
  import(/* webpackChunkName: "shop" */ '@/modules/shop/views/ShopStaff')

const shopSocialNetworks = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopSocialNetworks'
  )

const shopBranch = () =>
  import(/* webpackChunkName: "shop" */ '@/modules/shop/views/ShopBranches')

const shopImages = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopImages'
  )
const shopGallery = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopGallery'
  )
const shopGalleryNewImage = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/components/gallery/newImage'
  )

const shopGeneralSettings = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopGeneralSettings'
  )

const shopProductsSettings = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopProductsSettings'
  )

const ShopBillings = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopBillings'
  )

const ShopPromotions = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopPromotions'
  )

const ShopSchedule = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopSchedule'
  )

const shopSections = () =>
  import(/* webpackChunkName: "shop" */ '@/modules/shop/views/ShopSections')

const shopDomain = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopDomain'
  )
  
const shopEmails = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopEmails'
  )

const ShopCharges = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopCharges'
  )

const ShopContactBar = () =>
  import(
    /* webpackChunkName: "shop" */
    '@/modules/shop/views/ShopContactBar'
  )

export default [
  {
    path: '/tienda/usuarios',
    name: 'shopStaff',
    meta: {
      title: 'Administración de usuarios',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopStaff,
  },
  {
    path: '/tienda/proveedores',
    name: 'shopSuppliers',
    meta: {
      title: 'Administración de los proveedores',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopSuppliers,
  },
  {
    path: '/tienda/sucursales',
    name: 'shopBranch',
    meta: {
      title: 'Administración de sucursales',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopBranch,
  },
  {
    path: '/tienda/configuracion',
    name: 'shopGeneralSettings',
    meta: {
      title: 'Configuración de la Tienda',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopGeneralSettings,
  },
  {
    path: '/tienda/configuracion-de-productos',
    name: 'shopProductsSettings',
    meta: {
      title: 'Configuración de Productos',
      rule: 'manager',
    },
    component: shopProductsSettings,
  },
  {
    path: '/tienda/redes',
    name: 'shopSocialNetworks',
    meta: {
      title: 'Administración de redes sociales',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopSocialNetworks,
  },
  {
    path: '/tienda/imagenes',
    name: 'shopImages',
    meta: {
      title: 'Administración de imágenes de la tienda',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopImages,
  },
  {
    path: '/tienda/galeria',
    name: 'shopGallery',
    meta: {
      title: 'Administración de la galería de la tienda',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopGallery,
  },
  {
    path: '/tienda/galeria/nueva-imagen',
    name: 'shopGalleryNewImage',
    meta: {
      title: 'Nueva Imagen de la Galería',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopGalleryNewImage,
  },
  {
    path: '/tienda/facturacion',
    name: 'ShopBillings',
    meta: {
      title: 'Administración de la facturación',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: ShopBillings,
  },
  {
    path: '/tienda/promociones',
    name: 'ShopPromotions',
    meta: {
      title: 'Administración de las promociones',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: ShopPromotions,
  },
  {
    path: '/tienda/horario',
    name: 'ShopSchedule',
    meta: {
      title: 'Administración de los horarios',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: ShopSchedule,
  },
  {
    path: '/tienda/secciones',
    name: 'shopSections',
    meta: {
      title: 'Administración de secciones',
      rule: 'manager',
      // requiresAuth: true,
      // requiresShopAdmin: true,
      // requiresAdmin: false,
    },
    component: shopSections,
  },
  {
    path: '/tienda/dominio',
    name: 'shopDomain',
    meta: {
      title: 'Configuración del Dominio',
      rule: 'manager',
      //requiresAuth: true,
      //equiresShopAdmin: true,
      //requiresAdmin: false,
    },
    component: shopDomain,
  },
  {
    path: '/tienda/emails',
    name: 'shopEmails',
    meta: {
      title: 'Configuración de Emails',
      rule: 'manager',
    },
    component: shopEmails,
  },
  {
    path: '/tienda/cobros',
    name: 'ShopCharges',
    meta: {
      title: 'Administración de Cobros',
      rule: 'manager',
      //requiresAuth: true,
      //requiresShopAdmin: false,
      //requiresAdmin: false,
    },
    component: ShopCharges,
  },
  {
    path: '/tienda/barra-contacto',
    name: 'ShopContactBar',
    meta: {
      title: 'Administración de la barra de contacto',
      rule: 'manager',
    },
    component: ShopContactBar,
  },
]
