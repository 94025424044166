import { couponObject } from '@/models/cupons'
const _ = require('lodash')

export default {
  newCustomHtml: _.cloneDeep(couponObject),
  updateCustomHtml: _.cloneDeep(couponObject),
  selectedCustomHtml: _.cloneDeep(couponObject),

  customHtmlList: [],
  searchParams: '',
  totalCustomHtmlPages: 1,

}
