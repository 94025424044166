<template>
  <div class="loading-video-class">
    <vue-ellipse-progress
      :progress="percentage"
      :size="150"
    >
      <span slot="legend-value">%</span>
      <p slot="legend-caption">
        {{ msgText }}
      </p>
    </vue-ellipse-progress>
  </div>
</template>

<script>
export default {
  props: {
    percentage: { type: [Number], required: true, default: 0 },
    msgText: { type: [String], required: true },
  },
}
</script>

<style lang="scss">
.loading-video-class {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: hsla(0, 0%, 100%, 0.6);
  z-index: 300000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
