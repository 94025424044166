export default {
  setServicepaySearchParams: (state, servicepaySearchParams) => {
    state.servicepaySearchParams = servicepaySearchParams
  },
  setSearchIdate: (state, searchIdate) => {
    state.searchIdate = searchIdate
  },
  setSearchFdate: (state, searchFdate) => {
    state.searchFdate = searchFdate
  },
  setNotificationsSearchParams: (state, notificationsSearchParams) => {
    state.notificationsSearchParams = notificationsSearchParams
  },
}
