const listCategories = () =>
  import(
    /* webpackChunkName: "categories" */ '@/modules/categories/views/ListCategories.vue'
  )

const newCategorie = () =>
  import(
    /* webpackChunkName: "categories" */ '@/modules/categories/views/NewCategorie.vue'
  )

const editCategorie = () =>
  import(
    /* webpackChunkName: "categories" */ '@/modules/categories/views/EditCategorie.vue'
  )

export default [
  {
    path: '/categorias',
    name: 'listCategories',
    meta: {
      title: 'Administración de categorías',
      rule: 'manager',
    },
    component: listCategories,
  },
  {
    path: '/nueva-categoria/:id/',
    name: 'newCategorie',
    meta: {
      title: 'Nueva Categoría',
      rule: 'manager',
    },
    component: newCategorie,
  },
  {
    path: '/editar-categoria/:id/',
    name: 'editCategorie',
    meta: {
      title: 'Editar Categoría',
      rule: 'manager',
    },
    component: editCategorie,
  },
]
