import axios from 'axios'
const _ = require('lodash')


export async function newCustomHtml({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando Html',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop-custom-html/`, data)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Creando Html',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Creando Html',
          },
          { root: true }
        )
      })
  })
}


export async function updateCustomHtml({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando Html',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let id = data.id
    delete data.id
    axios
      .patch(`/shop-custom-html/${id}/?shop=${data.shop}`, data)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Actualizando Html',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Actualizando Html',
          },
          { root: true }
        )
      })
  })
}

export async function getCustomHtml({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando Html',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let id = data.id
    delete data.id
    axios
      .get(`/shop-custom-html/${id}/?shop=${data.shop}`)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando Html',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando Html',
          },
          { root: true }
        )
      })
  })
}

export async function getCustomHtmlList({ commit }, params) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando Html',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop-custom-html/?${params}&admin=true`)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando Html',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando Html',
          },
          { root: true }
        )
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateAvailableCustomHtmlAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop-custom-html/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
