export default {
  setSales: (state, sales) => {
    state.sales = sales
  },
  setProducts: (state, products) => {
    state.products = products
  },
  setDay: (state, day) => {
    state.day = day
  },
  setDayF: (state, dayF) => {
    state.dayF = dayF
  },
  setSalesSearchParams: (state, salesSearchParams) => {
    state.salesSearchParams = salesSearchParams
  },
  setSearchIdate: (state, searchIdate) => {
    state.searchIdate = searchIdate
  },
  setSearchFdate: (state, searchFdate) => {
    state.searchFdate = searchFdate
  },
  setRulesMsi: (state, rulesMsi) => {
    state.rulesMsi = rulesMsi
  },
}
