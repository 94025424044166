import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function newSaleAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/sales/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene los detalles de las ventas de un día.
 *
 * @param shopId Identificador de la tienda.
 * @param day día a buscar.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getDateSales({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/sales/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newClientAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/clients/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getProductVariants({ commit }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/products-sale/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function sendTicketEmail({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/sales/send-ticket-email/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateShippingStatusAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/shipping-sale/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getManagerAnalytics({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/sales/manager-analytics-totals/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getManagerAnalyticsSales({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/sales/manager-analytics-sales/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene los detalles de las reglas de msi.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getRulesMsi({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/rules-msi/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getMsi({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/catalogs/msi/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createRule({ commit }, { data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/rules-msi/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateRule({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/rules-msi/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
