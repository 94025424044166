import axios from 'axios'
const _ = require('lodash')

export async function getProductsInfo({commit}, params = null){
  return new Promise((resolve, reject) => {
    let url = `/products-inventory/`
    url = params !== null ? `${url}?${params}` : url
    axios
    .get(url)
    .then(response => {
      resolve(response.data)
    })
    .catch(error => {
      reject(error)
    })
  })

}

export async function getStockMovements({commit}, {shopId}, params = null){
  return new Promise((resolve, reject) => {
    let url = `/products-movements/${shopId}`
    url = params !== null ? `${url}?${params}` : url
    axios
    .get(url)
    .then(response => {
      resolve(response.data)
    })
    .catch(error => {
      reject(error)
    })
  })

}

export async function updateProductQuantity({commit}, {data, shopId}) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando stock',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/products-inventory/${shopId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
