import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function getShopTypeCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/shop-type/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateShopType({ commit }, { data, id }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/shop-type/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createShopType({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/shop-type/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getPackageCatalog({ commit }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/packages/'
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSertiAccount({ commit }, id) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/account/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createSertiAccountAction({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/account/create-account/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getGlobalCommissions({ commit }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/global-commissions/latest-values/'
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateCommissions({ commit }, { data, id }) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando comisiones',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put(`/global-commissions/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function generateShop({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/shop-create/generate-shop/'
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function resendInvitation({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Reenviando invitación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = '/shop-resend/resend-invitation/'
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getListShop({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = data === undefined ? '/shop/' : `/shop/${data}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateShopSubscription({ commit }, { shopId, data }) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando suscripción',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put(`/shop-subscription/${shopId}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function createShopSubscription({ commit }, { data }) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando suscripción',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop-subscription/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateItemCatalog({ commit }, { id, data, shop }) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando catálogo',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put(`/catalogs/additional-information/${id}/?shop=${shop}`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function CheckItemCatalog({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/catalogs/additional-information/check-information/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function deleteItemCatalog({ commit }, { id, data, shop }) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Borrando catálogo',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .delete(`/catalogs/additional-information/${id}/?shop=${shop}`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newItemCatalog(store, { data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/catalogs/additional-information/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene el catálogo de tipo de tienda.
 *
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getTypeShopCatalog({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/shop-type/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newItemCatalogAdmin(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/${type}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateItemCatalogAdmin(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getManualCatalog({ commit }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/manuals/'
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newManual({ commit }, { formData }) {
  return new Promise((resolve, reject) => {
    let url = `/catalogs/manuals/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateManual({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/catalogs/manuals/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene el catálogo de tipo de tienda.
 *
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getCommissionInfo({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = `/shop-commissions/${id}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateShopCommission({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/shop-commissions/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateShopIsActive({ commit }, { slug, data }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/shop/${slug}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getPrivacyNotice({ commit }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/privacy-notice/'
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updatePrivacyNotice({ commit }, { id, data }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/privacy-notice/${id}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getAnalytics({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/sales/analytics-totals/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getAnalyticsSales({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/sales/analytics-sales/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createNotification({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/notifications/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getNotificationHistory({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/notifications/history/`
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSystemNotifiactions({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/system-notifiactions/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function readSystemNotifiaction({ commit }, id) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/system-notifiactions/${id}/`
    axios
      .patch(url, { read: true })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
