import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function getServicePayCategories({ commit }, flow) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/catalogues/categories/?flow=${flow}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getServicePaySubcategories({ commit }, category) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/catalogues/subcategories/?category=${category}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getServicePayCarriersRecharges({ commit }, category) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/catalogues/carriers/?isSubCategory=false&filter=${category}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getServicePayCarriersServices({ commit }, subcategory) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/catalogues/carriers/?isSubCategory=true&filter=${subcategory}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getServicePayProducts({ commit }, carrier) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/catalogues/products/?carrier=${carrier}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getServicePayProductDetail({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/catalogues/product-detail/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getEmidaAccount({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/account/${shopId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function registerEmidaAccount({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/account/register_account/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getBalance({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/account/balance/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function servicePay({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/payment/pay-service/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function rechargesPay({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/payment/pay-recharges/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function retryPayment({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/servicepay/payment/retry-payment/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getTransactionsHistory({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/servicepay/transaction/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getNotificationUtil({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/servicepay/account/notification-util/'
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function paymentNotification({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/servicepay/account/payment-notification/'
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getNotificationHistory({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = 'servicepay/payment-notifiaction/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
