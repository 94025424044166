<template>
  <div id="app">
    <transition name="slide-fade">
      <navbar v-if="isLogged && showNavbar" />
    </transition>
    <transition name="slide-fade">
      <main-sidebar v-if="isLogged" />
    </transition>
    <main>
      <transition name="slide-fade">
        <router-view />
      </transition>
    </main>
    <transition name="slide-fade">
      <app-footer />
    </transition>
    <video-loading
      v-if="videoLoading"
      :percentage="loadingPercentage"
      :msg-text="loadingTextVideo"
    />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import AppFooter from '@/components/Footer'
import MainSideBar from '@/components/MainSideBar'
import VideoLoading from '@/components/VideoLoading'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    navbar: NavBar,
    'main-sidebar': MainSideBar,
    'app-footer': AppFooter,
    'video-loading': VideoLoading,
  },
  computed: {
    ...mapState([
      'activeLoading',
      'loadingPageMsg',
      'showNavbar',
      'videoLoading',
      'loadingPercentage',
      'loadingTextVideo',
    ]),
    ...mapGetters('auth', { isLogged: 'isLogged' }),
    ...mapState('auth', { user: 'user' }),
  },
  watch: {
    activeLoading: function (isLoading) {
      // Don't use Arrow Functions for this case
      if (isLoading) {
        this.$vs.loading({
          text: this.loadingPageMsg,
        })
      } else {
        this.$vs.loading.close()
      }
    },
    $route() {
      this.checkEmidaWidget()
    },
  },
  async created() {
  await this.redirectFeenicia()
    if (this.isLogged) {
      this.getUser(this.user.username).then((response) => {
        const user = {
          user_id: response.id,
          username: response.username,
          name: response.name,
          lastName: response.lastName,
          secondLastName: response.secondLastName,
          email: response.email,
          phoneNumber: response.phoneNumber,
          userprofile: {
            role: response.userprofile.role,
            picture: '#',
            shop: {
              id: response.userprofile.shop.id,
              name: response.userprofile.shop.name,
              picture: response.userprofile.shop.picture,
              email: response.userprofile.shop.email,
              address: response.userprofile.shop.address,
              slug: response.userprofile.shop.slug,
              numberOfCategories: response.userprofile.shop.numberOfCategories,
            },
          },
        }

        this.setUser(user)
        this.getShop(user.userprofile.shop.slug).then((response) => {
          this.setShop(response)
        })
        this.getSite().then((response) => {
          this.setSite(response.site)
        })
      })
    }
  },
  methods: {
    ...mapActions('users', {
      getUser: 'getUser',
    }),
    ...mapMutations('auth', {
      setUser: 'setUser',
    }),
    ...mapMutations('shop', {
      setShop: 'setShop',
      setSite: 'setSite',
    }),
    ...mapActions('shop', {
      getShop: 'getShop',
      getSite: 'getSite',
      RedirectSertiAccountAction:'RedirectSertiAccountAction',
    }),
    checkEmidaWidget() {
      const self = this
      const intervalTime = 150
      if (
        window.location.pathname.includes('/servicios/notificar') ||
        window.location.pathname.includes('/servicios/pago') ||
        window.location.pathname.includes('servicios/historial')
      ) {
        setTimeout(() => {
          let widget = document.getElementById('zsiq_float')
          let iframe = document.getElementById('siqiframe')
          let recall = widget === null || iframe === null
          if (widget !== null) {
            widget.style.display = 'block'
          }
          if (iframe !== null) {
            iframe.style.display = 'block'
          }
          if (recall) {
            setTimeout(function () {
              self.checkEmidaWidget()
            }, intervalTime)
          }
        }, intervalTime)
      } else {
        setTimeout(() => {
          let widget = document.getElementById('zsiq_float')
          let iframe = document.getElementById('siqiframe')
          let recall = widget === null || iframe === null
          if (widget !== null) {
            widget.style.display = 'none'
          }
          if (iframe !== null) {
            iframe.style.display = 'none'
          }
          if (recall) {
            setTimeout(function () {
              self.checkEmidaWidget()
            }, intervalTime)
          }
        }, intervalTime)
      }
    },
    /**
     * metodo para ir al endponit con los datos
     */
    async redirectFeenicia() {
      console.log(window.location.search)
      if (window.location.search === '') {
        return 0
      } else {
        let string_query = window.location.search
        string_query = window.location.search.split('&')
        if (!string_query.length > 1) {
          console.log(string_query.length)
          return 0
        }

        let token = string_query[0]
        let id = string_query[1]
        token = token.split('=')
        token[0] = token[0].slice(1)
        id = id.split('=')
        if (!token.length > 1 || token[0] !== 'tokenFeenicia' || !id.length > 1 || id[0] !== 'serti_account_id') {
          return 0
        }

        let data = {
          token: token[1],
          serti_account_id: id[1],
        }
        console.log(data)
        await this.RedirectSertiAccountAction(data).then((response) => {
          console.log(response)
        })
        //this.RedirectSertiAccountAction(data).catch((err) => {})
      }
    },
  },
}
</script>
