import axios from 'axios'

/**
 * Envía la petición al servidor para el envío de la invitación de nuevo usuario.
 * @param data Object Objeto con la información del paciente
 * @returns {Promise<Object>}
 */
export async function inviteUserAction({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando email de invitación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/invitation/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene los detalles de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getShop({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/shop/${shopId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene el sitio.
 *
 *
 *
 * @returns {string}
 */
// eslint-disable-next-line no-unused-vars
export async function getSite({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/shop/get-site/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene el tipo de tienda.
 *
 * @param type Identificador de el tipo de tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getTypeShop({ commit }, type) {
  return new Promise((resolve, reject) => {
    let url = `/shop-type/${type}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateShop(
  { commit },
  { data, shopId, close_loading = true }
) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/shop/${shopId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        if (close_loading === true) {
          commit('setLoading', false, { root: true })
        }
      })
  })
}

/**
 * Obtiene las redes sociales de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getSocialNetworks({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/social-networks/${shopId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateSocialNetworks({ commit }, { data, shopId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando redes sociales de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/social-networks/${shopId}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene los detalles de la facturación.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getBilling({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/billing/${shopId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getShopCatalog({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/shop/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateBilling({ commit }, { data, shopId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando la facturación de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/billing/${shopId}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene las imagenes de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getShopImages({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/shop-logo/${shopId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la gallería de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getShopGallery({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/gallery/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite actualizar una imagen de la galería .
 *
 * @param {Object} obj.formData FormData con la imagen de la galería.
 * @param {Object} obj.id Identificador de la imagen
 */
// eslint-disable-next-line no-unused-vars
export async function updateImageGallery({ commit }, { formData, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando la imagen de la galería',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/gallery/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Permite actualizar la disponibilidad de una imagen de la galería .
 *
 * @param {object} data  data con los datos a cambiar de la imagen.
 * @param {Object} obj.id Identificador de la imagen
 */
// eslint-disable-next-line no-unused-vars
export async function updateAvailableImageGallery({ commit }, { data, id }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/gallery/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite crear una imagen de la galería .
 *
 * @param {Object} obj.formData FormData con la imagen de la galería.
 *
 */
// eslint-disable-next-line no-unused-vars
export async function createImageGallery({ commit }, { formData }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando la imagen de la galería',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/gallery/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Permite actualizar las imagenes utilizadas en la tienda.
 *
 * @param {Object} obj.formData FormData con las imagenes de la tienda.
 * @param {Object} obj.id Identificador de la tienda
 */
// eslint-disable-next-line no-unused-vars
export async function updateShopImages({ commit }, { formData, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando las imagenes de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/shop-logo/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateCatalogFile({ commit }, { formData, slug }) {
  return new Promise((resolve, reject) => {
    let url = `/shop/${slug}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateShopHeight({ commit }, { data, shopId }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/shop-logo/${shopId}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene los horarios de la tienda.
 *
 * @param branchId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getShopSchedule({ commit }, branchId) {
  return new Promise((resolve, reject) => {
    let url = `/shop-schedule/?branch=${branchId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateSchedule({ commit }, { data, scheduleId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando el horario de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/shop-schedule/${scheduleId}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createSchedule({ commit }, { data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop-schedule/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene las sucursales de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getBranches({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/branch/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene las sucursales de la tienda con filtro de busqueda.
 *
 * @param shopId Identificador de la tienda.
 * @param searchParams nombre sucursal
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function findBranches({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/branch/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateBranch({ commit }, { data, branchId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de la sucrsal',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/branch/${branchId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function createBranch({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando sucursal',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/branch/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene los detalles de la promoción.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getPromotions({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/promotions/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updatePromotions({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando las promociones de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/promotions/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene las secciones de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getSections({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/sections/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function createSection({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando sección',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/sections/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateSection({ commit }, { data, sectionId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando sección',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/sections/${sectionId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function stripeOnBoardUser({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/stripe/onboard-user/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene las sucursales de la tienda.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getStripeAccountInfo({ commit }, shopId) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Consultando información',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/stripe/account-info/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateOfficialIdentification({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/documnetation/contact-file/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la gallería de la tienda.
 *
 * @param data informacion.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function updateRfcFile({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/documnetation/invoice-rfc-file/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la gallería de la tienda.
 *
 * @param data informacion.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function updateIncorporationFile({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/documnetation/invoice-incorporation-file/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la gallería de la tienda.
 *
 * @param data informacion.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function updateAddressFile({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/documnetation/address-file/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la gallería de la tienda.
 *
 * @param data informacion.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function updateBankAccountFile({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/documnetation/bank-account-file/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getDynamicEmails({ commit }, shop) {
  return new Promise((resolve, reject) => {
    commit(
      'setLoading',
      {
        isLoading: true,
        msg: 'Cargando configuración de emails',
      },
      { root: true }
    )
    let url = `/dynamic_emails/?shop=${shop}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Almacena la información de configuración de emails dinámicos
 */
export async function updateDynamicEmail({ commit }, data) {
  return new Promise((resolve, reject) => {
    commit(
      'setLoading',
      {
        isLoading: true,
        msg: 'Actualizando configuración de emails',
      },
      { root: true }
    )
    axios
      .put(`/dynamic_emails/${data.id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Almacena la información de configuración de emails dinámicos
 */
export async function testDynamicEmail({ commit }, data) {
  return new Promise((resolve, reject) => {
    commit(
      'setLoading',
      {
        isLoading: true,
        msg: 'Enviando email de prueba',
      },
      { root: true }
    )
    axios
      .post(`/dynamic_emails/send-test-email/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene la barra de contacto.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getContactBar({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/contact-bar/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la info de contacto.
 *
 * @param shopId Identificador de la tienda.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getContactInfo({ commit }, shopId) {
  return new Promise((resolve, reject) => {
    let url = `/branch/get-primary/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateContactBar({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando la barra de contacto',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/contact-bar/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
