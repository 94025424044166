const BillingView = () =>
  import(
    /* webpackChunkName: "billing" */ '@/modules/billing/views/BillingView'
  )

export default [
  {
    path: '/billing',
    name: 'billing',
    meta: {
      title: 'Facturación',
      rule: 'manager',
    },
    component: BillingView,
  },
]
