export let baseUser = {
  user_id: null,
  username: '',
  name: '',
  lastName: '',
  secondLastName: '',
  email: '',
  phoneNumber: '',
  isNew: null,
  userprofile: {
    role: '',
    picture: '',
    shop: {
      id: null,
      name: '',
      slug: '',
      picture: '',
      email: '',
      phone: '',
      address: '',
      numberOfCategories: null,
    },
  },
}
