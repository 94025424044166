const NewsletterClientsView = () =>
  import('@/modules/clients/views/NewsletterClientsView.vue')

const NewsletterConfigurationView = () =>
  import('@/modules/clients/views/NewsletterConfigurationView.vue')

export default [
  {
    path: '/clientes/newsletter',
    name: 'NewsletterClient',
    meta: {
      title: 'Lista de Suscriptores al Newsletter',
      rule: 'manager',
    },
    component: NewsletterClientsView,
  },
  {
    path: '/clientes/configuracion-newsletter',
    name: 'NewsletterConfiguration',
    meta: {
      title: 'Configuración del Newsletter',
      rule: 'manager',
    },
    component: NewsletterConfigurationView,
  },
]
