import { productObject } from '@/models/products'
const _ = require('lodash')

export default {
  newProduct: _.cloneDeep(productObject),
  updateProduct: _.cloneDeep(productObject),
  selectedProduct: _.cloneDeep(productObject),
  newProductFormsProgress: {
    generalInfo: {
      productData: false,
    },
    atributes: {
      atributesData: false,
    },
    adicional: {
      adicionalData: false,
    },
    quickSelection: {
      quickSelectionData: false,
    },
    variants: {
      variantsData: false,
    },
  },
  productPhotos: new FormData(),
  productsList: [],
  searchParams: '',
  totalProductsPages: 1,
  fileVideo: new File([''], 'filename'),
  relatedProducts: [],
  productPacks: [],
}
