import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyCH83WGOG-kQ9eySRXzMvhjR1fI-GpDSWM',
    libraries: 'places,drawing,visualization', // This is required if you use the Auto complete plug-in
    language: 'es-419',
    region: 'MX',
  },
})
