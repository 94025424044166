const CatalogsView = () =>
  import(
    /* webpackChunkName: "catalogs" */ '@/modules/catalogs/views/CatalogsView'
  )
const SupplierView = () =>
  import(
    /* webpackChunkName: "catalogs" */ '@/modules/catalogs/views/SupplierView'
  )

export default [
  {
    path: '/catalogos',
    name: 'catalogs',
    meta: {
      title: 'Catálogos',
      rule: 'manager',
    },
    component: CatalogsView,
  },
  {
    path: '/catalogos/proveedor/:id/',
    name: 'supplier',
    meta: {
      title: 'Proveedor',
      rule: 'manager',
    },
    component: SupplierView,
  },
]
