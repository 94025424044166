// eslint-disable-next-line no-unused-vars
import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function newSupplier({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando Proveedor',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post(`/catalogs/supplier/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSupplier({ commit }, id) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/catalogs/supplier/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getSupplierList({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/supplier/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateSupplierCatalog({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando Proveedor',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put(`/catalogs/supplier/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateSupplierHome({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando Proveedor',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put(`/catalogs/supplier/${data.id}/`, data)
      .then((response) => {
        commit('setLoading', false, { root: true })
        resolve(response.data.results)
      })
      .catch((error) => {
        commit('setLoading', false, { root: true })
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateSupplierImg({ commit }, { formData, id }) {
  // Set loading page animation
  // commit(
  //   'setLoading',
  //   {
  //     isLoading: true,
  //     msg: 'Actualizando Proveedor',
  //   },
  //   { root: true }
  // )
  return new Promise((resolve, reject) => {
    let url = `/catalogs/supplier/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
