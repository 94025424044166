import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/router'
import catalogsRoutes from '@/modules/catalogs/router'
import productsRoutes from '@/modules/products/router'
import shopRoutes from '@/modules/shop/router'
import usersRoutes from '@/modules/users/router'
import marketingRoutes from '@/modules/marketing/router'
import serverRoutes from '@/modules/server/router'
import store from '@/store'
import categoriesRoutes from '@/modules/categories/router'
import lotRoutes from '@/modules/lot/router'
import salesRoutes from '@/modules/sales/router'
import shippingRoutes from '@/modules/shipping/router'
import systemRoutes from '@/modules/system/router'
import suppliersRoutes from '@/modules/suppliers/router'
import clientsRoutes from '@/modules/clients/router'
import servicepayRoutes from '@/modules/servicepay/router'
import couponsRoutes from '@/modules/coupons/router'
import customHtmlRoutes from '@/modules/custom_html/router'
import inventoryRoutes from '@/modules/inventory/router'
import billingRoutes from '@/modules/billing/router'
//import shopRoutes from '@/modules/shopstripe/router'

Vue.use(VueRouter)

let baseRoutes = [
  {
    path: '*',
    name: 'index',
    redirect: {
      name: 'authLogin',
    },
  },
]

// noinspection JSCheckFunctionSignatures
const routes = baseRoutes.concat(
  customHtmlRoutes,
  authRoutes,
  catalogsRoutes,
  productsRoutes,
  shopRoutes,
  usersRoutes,
  marketingRoutes,
  serverRoutes,
  categoriesRoutes,
  lotRoutes,
  salesRoutes,
  shippingRoutes,
  systemRoutes,
  suppliersRoutes,
  clientsRoutes,
  servicepayRoutes,
  couponsRoutes,
  inventoryRoutes,
  billingRoutes,
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeResolve((to, from, next) => {
  const profile = store.getters['auth/getUserProfile']
  let canceledPlan = false
  
  store.dispatch('shop/getShop', profile.shop.slug)
    .then((response) => {
      canceledPlan = response.shopSubscription?.canceledPlan ?? false
      if (canceledPlan && to.name !== 'Subscription') {
        next({ name: 'Subscription-expired' });
      }
    })
  next()
})

 router.afterEach(() => {
   const Stickedtooltips = document.querySelectorAll('.vs-tooltip')
   for (const tooltip of Stickedtooltips) {
     tooltip.remove()
   }
 })
 export default router
