const NewLotView = () =>
  import(/* webpackChunkName: "lot" */ '@/modules/lot/views/NewLot.vue')

const ListLotView = () =>
  import(/* webpackChunkName: "lot" */ '@/modules/lot/views/ListLot.vue')

const UpdateLotView = () =>
  import(/* webpackChunkName: "lot" */ '@/modules/lot/views/EditLot.vue')

export default [
  {
    path: '/lote/nuevo/:id',
    name: 'newLot',
    meta: {
      title: 'Nuevo Lote',
      rule: 'manager',
    },
    component: NewLotView,
  },
  {
    path: '/lote/:id',
    name: 'listLot',
    meta: {
      title: 'Historial de Lotes',
      rule: 'manager',
    },
    component: ListLotView,
  },
  {
    path: '/lote/edit/:id',
    name: 'editLot',
    meta: {
      title: 'Editar Lote',
      rule: 'manager',
    },
    component: UpdateLotView,
  },
]
