import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function getNewsletterClients({ commit }, params) {
  return new Promise((resolve, reject) => {
    let url = params === undefined ? '/newsletter/' : `/newsletter/${params}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getNewsletterConfiguration({ commit }) {
  return new Promise((resolve, reject) => {
    let url = '/newsletter-configuration/'
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateNewsletterConfiguration(
  { commit },
  { data, configurationId }
) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando Configuración del Newsletter',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/newsletter-configuration/${configurationId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      })
  })
}
