import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function newLotAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/lots/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateLotAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/lots/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getLotList({ state }, { id, page }) {
  return new Promise((resolve, reject) => {
    let url = `/lots/?product=${id}&page=${page}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getLot({ state }, id) {
  return new Promise((resolve, reject) => {
    let url = `/lots/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite eliminar una cita de la base de datos
 * @param {Number} id Identificar de la cita que se eliminará
 */
export async function deleteLotAction({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Eliminando lote...',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    let url = `/lots/${id}/`
    axios
      .delete(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getNewData({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/lots/new-data/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function uploadFile({ commit }, { data, id }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/lots/${id}/`
    axios
      .patch(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
