import axios from 'axios'

// eslint-disable-next-line no-unused-vars
export async function newItemCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/${type}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateItemCatalog(store, { type, data }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/${type}/${data.id}/`, data)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getShippingPriceCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/shipping-price/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getShippingTimeCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/shipping-period/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.results)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getStatesCatalog({ commit }, params = null) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = '/catalogs/states/'
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getListRules({ commit }, { shopId, data }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url =
      data === undefined
        ? `/shipping-state/?shop=${shopId}`
        : `/shipping-state/?shop=${shopId}&${data}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getListRulesByCp({ commit }, { shopId, data }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url =
      data === undefined
        ? `/shipping-cp/?shop=${shopId}`
        : `/shipping-cp/?shop=${shopId}&${data}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateRule({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando regla',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/shipping-state/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateRuleCp({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando regla',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/shipping-cp/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function makeRules({ commit }, { data }) {
  return new Promise((resolve, reject) => {
    let url = `/shipping-cp/make-rules/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function validateCp({ commit }, data) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/shipping-cp/validate-cp/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createRuleCp({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando nueva regla',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/shipping-cp/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getShippmentData({ commit }, { data }) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    axios
      .post(`/catalogs/states/cp-info/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getPackaging({ commit }, shopId) {
  // Set loading page animation
  return new Promise((resolve, reject) => {
    let url = `/packaging/?shop=${shopId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createPackaging({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando nueva paquete',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/packaging/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updatePackaging({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando paquete',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/packaging/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
