const CustomHtmlView = () =>
  import('@/modules/custom_html/views/CustomHtmlView.vue')

const CreateCustomHtmlView = () => import('@/modules/custom_html/views/CreateCustomHtmlView.vue')

const ListCustomHtmlsView = () =>
  import('@/modules/custom_html/views/ListCustomHtmlsView.vue')

export default [
  {
    path: '/html',
    name: 'HTML-embebido',
    meta: {
      title: 'HTML',
      rule: 'manager',
    },
    component: ListCustomHtmlsView,
  },
  {
    path: '/agregar-html',
    name: 'newCustomHtml',
    meta: {
      title: 'Nuevo Html',
      rule: 'manager',
    },
    component: CreateCustomHtmlView,
  },
  {
    path: '/detalle-html/:id',
    name: 'CustomHtml',
    meta: {
      title: 'CustomHtml',
      rule: 'manager',
    },
    component: CustomHtmlView,
  },
]
