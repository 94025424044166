/**
 * @return {string}
 */
const utils = {
  randomId: function () {
    return `_${Math.random().toString(36).substr(2, 9)}`
  },
  calculateAge: function (dateString) {
    let today = new Date()
    let birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },
  /**
   * Modifica el string de error para hacerlo más legible.
   *
   * @param {String} msg Cadena de error
   */
  formatMsg(msg) {
    msg = msg.replace('non_field_errors: ', '')
    msg = msg.replace('[object Object]', '')
    msg = msg.replace('productDetailVariantValueAdd:', '')
    msg = msg.replace('name', 'Nombre')
    msg = msg.replace('product_detail_add', 'Variante')
    msg = msg.replace('quick_selection_add', 'Selección Rápida')
    msg = msg.replace('photosAdd', 'Fotos')
    msg = msg.replace('sku', 'SKU')
    msg = msg.replace('price', 'Precio')
    msg = msg.replace('product_code', 'Código de Producto')
    msg = msg.replace('banner_final', 'Banner final')
    msg = msg.replace('banner_final_mobile', 'Banner final móvil')
    msg = msg.replace('banner_final_text', 'Banner final texto')
    msg = msg.replace('banner_final_text_color', 'Banner final color de texto')
    msg = msg.replace('banner_final_text_align', 'Banner final texto alineado')
    msg = msg.replace('banner_final_text_size', 'Banner final tamaño del texto')
    msg = msg.replace('banner_final_text_weight', 'Banner final peso del texto')
    msg = msg.replace('banner_final_proportion', 'Banner final proporcion')
    msg = msg.replace('banner_final_splitted', 'Banner final dividido')
    msg = msg.replace('banner_final_html', 'Banner final html')
    msg = msg.replace('height_banner_final', 'Altura banner final')
    msg = msg.replace('height_banner_final_mobile', 'Altura banner final móvil')
    msg = msg.replace('detail:', 'Detalle:')
    msg = msg.replace('photo_compress', 'Imagen principal')

    return msg
  },
  getErrorDetails: function (errors, productDetail = []) {
    var toType = function (obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
    }

    let errorDetails = ''
    let messages = ''
    errors.some((err) => {
      if (toType(err['message']) === 'object') {
        let messagesObj = err['message']
        Object.keys(messagesObj).some((messageInput) => {
          if (toType(messagesObj[messageInput]) === 'object') {
            let messagesObj2 = messagesObj[messageInput]
            Object.keys(messagesObj2).some((messageInput2) => {
              let formatMessage2 = messagesObj2[messageInput2]
              messages += `${messageInput2}: ${formatMessage2} \n`
            })
          } else {
            let formatMessage = messagesObj[messageInput]
            messages += `${messageInput}: ${formatMessage} \n`
          }
          return true
        })
      } else {
        messages = err['message']
      }
      let field = err['field']
      if (field === 'product_detail_add') {
        field += `(${productDetail[Object.keys(err['message'])[0]].name})`
      }
      errorDetails += `${field}: ${messages} \n`
      return true
    })

    return utils.formatMsg(errorDetails)
  },
  getFileNameFromUrl: function (url) {
    return url.substring(url.lastIndexOf('/') + 1)
  },
  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  notBeforeToday: function (date) {
    return date < new Date(new Date().setHours(0, 0, 0, 0) + 1)
  },
  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  BeforeToday: function (date) {
    return date > new Date(new Date().setHours(0, 0, 0, 0) + 1)
  },

  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  Over18: function (date) {
    let over18 = new Date()
    over18.setFullYear(over18.getFullYear() - 18)
    return date > over18
  },

  getFileExtension1: function (filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined
  },
  getUrlVars: function () {
    let vars = {}
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
      m,
      key,
      value
    ) {
      vars[key] = value
    })
    return vars
  },
  /**
   * Da formato a una fecha y hora (Datetime) de python
   * @param {String} dt Datetime a formatear
   * @param {String} charInDt Carácter separador entre fecha y horas
   * @param {String} charReplace Carácter que remplazará
   */
  formatDateTime(dt, charInDt = 'T', charReplace = ' ') {
    return dt.replace(charInDt, charReplace)
  },
  /**
   * Objeto con la lista de roles disponibles.
   *
   */
  roles: {
    ADMIN: 'admin',
    MANAGER: 'manager',
    SELLER: 'seller',
  },

  mensajeEmidaFormatear(msg) {
    msg = msg.replaceAll('Ã', 'Á')
    msg = msg.replaceAll('Ã‰', 'É')
    msg = msg.replaceAll('Ã', 'Í')
    msg = msg.replaceAll('Ã“', 'Ó')
    msg = msg.replaceAll('Ãš', 'Ú')

    msg = msg.replaceAll('Ã¡', 'á')
    msg = msg.replaceAll('Ã©', 'é')
    msg = msg.replaceAll('Ã³', 'ó')
    msg = msg.replaceAll('Ãº', 'ú')
    msg = msg.replaceAll('Ã', 'í')
    return msg
  },
}
module.exports = utils
