const ShippingRulesListView = () =>
  import('@/modules/shipping/views/ShippingRulesListView.vue')

const PackagingView = () => import('@/modules/shipping/views/PackagingView.vue')

export default [
  {
    path: '/reglas-de-envios',
    name: 'shippingRules',
    meta: {
      title: 'Reglas de envíos',
      rule: 'manager',
    },
    component: ShippingRulesListView,
  },
  {
    path: '/paquetes-de-envio',
    name: 'Packaging',
    meta: {
      title: 'Paquetes de envíos',
      rule: 'manager',
    },
    component: PackagingView,
  },
]
