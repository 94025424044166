const Err400View = () =>
  import(/* webpackChunkName: "server" */ '@/modules/server/views/Err400View')

export default [
  {
    path: '/400',
    name: 'err400',
    meta: {
      title: 'Bad Request',
      requiresAuth: false,
      requiresShopAdmin: false,
      requiresAdmin: false,
    },
    component: Err400View,
  },
]
