export default {
  updateCardListActive: (state, status) => {
    state.cardListActive = status
  },
  updateCardNewItemActive: (state, status) => {
    state.cardNewItemActive = status
  },
  updateTitleCard: (state, title) => {
    state.titleCard = title
  },
  updateTypeSubmit: (state, type) => {
    state.typeSubmit = type
  },
  //-----------------------------------
  setSelectedRule: (state, selectedRule) => {
    state.selectedRule = selectedRule
  },
  setRuleList: (state, ruleList) => {
    let priceRules = []
    let cheapPrice = 0
    let highPrice = 0
    let listData = []
    let cpIds = []
    let data
    let temp
    let range
    ruleList.map((list) => {
      if (list.rules.length > 0) {
        cheapPrice = list.rules[0].shippingPrice
        highPrice = list.rules[0].shippingPrice
      }
      if (list.hasOwnProperty('postalCodeCatalog')) {
        cpIds.push({
          id: list.id,
          name: list.postalCodeCatalog.postalCode,
        })
      } else {
        cpIds.push({
          id: list.id,
          name: list.postalCodeStateCatalog.name,
        })
      }
      if (list.rules.length > 0) {
        list.rules.map((rule) => {
          if (rule.finalPrice != -1) {
            range = [rule.startingPrice, rule.finalPrice]
          } else {
            range = [rule.startingPrice, rule.startingPrice]
          }
          if (rule.shippingPrice > highPrice) highPrice = rule.shippingPrice
          if (rule.shippingPrice < cheapPrice) cheapPrice = rule.shippingPrice
          temp = {
            value: parseInt(rule.startingPrice),
            range: range,
            price: rule.shippingPrice,
          }
          priceRules.push(temp)
        })
      }
      if (list.hasOwnProperty('postalCodeCatalog')) {
        data = {
          id: list.id,
          isActive: list.isActive,
          postalCodeCatalog: list.postalCodeCatalog,
          rules: priceRules,
          cheapPrice: cheapPrice,
          highPrice: highPrice,
          settlements: list.settlements,
          shippingPeriod: list.shippingPeriod,
          shop: list.shop,
        }
      } else {
        data = {
          id: list.id,
          isActive: list.isActive,
          postalCodeStateCatalog: list.postalCodeStateCatalog,
          rules: priceRules,
          cheapPrice: cheapPrice,
          highPrice: highPrice,
          shippingPeriod: list.shippingPeriod,
          shop: list.shop,
        }
      }
      listData.push(data)
      priceRules = []
      cheapPrice = 0
      highPrice = 0
    })
    state.rulesIds = cpIds
    state.ruleList = listData
  },
  setTotalShopPages: (state, totalShopPages) => {
    state.totalShopPages = totalShopPages
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setDetail: (state, detail) => {
    state.detail = detail
  },
  setStates: (state, states) => {
    state.states = states
  },
  setPackaging: (state, packaging) => {
    state.packaging = packaging
  },
}
