import { productObject } from '@/models/products'
const _ = require('lodash')

export default {

  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setCustomHtmlList: (state, customHtmlList) => {
    state.customHtmlList = customHtmlList
  },
  setTotalCustomHtmlPages: (state, pages) => {
    state.totalCustomHtmlsPages = pages
  },
  
  setUpdateCustomHtmlData: (state, updateCustomHtml) => {
    state.updateCustomHtml = _.merge(state.updateCustomHtml, updateCustomHtml)
  },
  clearUpdateCustomHtmlData: (state) => {
    state.updateCustomHtml = _.cloneDeep(productObject)
  },
}
