import axios from 'axios'
import { productObject } from '@/models/products'
const _ = require('lodash')


export async function newCoupon({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando cupón',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post(`/discount-coupons/`, data)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Creando cupón',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Creando cupón',
          },
          { root: true }
        )
      })
  })
}

export async function applyCoupon({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Aplicando cupón',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post(`/apply-coupons/`, data)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Aplicando cupón',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Aplicando cupón',
          },
          { root: true }
        )
      })
  })
}

export async function getCouponApplies({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando cupón',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .get(`/apply-coupons/${id}`)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando cupón',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando cupón',
          },
          { root: true }
        )
      })
  })
}


export async function getCouponList({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando cupones',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .get(`/discount-coupons/`)
      .then((response) => {
        resolve(response.data)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando cupones',
          },
          { root: true }
        )
      })
      .catch((error) => {
        reject(error)
        commit(
          'setLoading',
          {
            isLoading: false,
            msg: 'Cargando cupones',
          },
          { root: true }
        )
      })
  })
}


// eslint-disable-next-line no-unused-vars
export async function updateAvailableCouponAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/discount-coupons/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getCouponsList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/discount-coupons/'
    url = params !== null ? `${url}?${params}` : url
    state.selectedProduct = _.cloneDeep(productObject)
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
