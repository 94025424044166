import axios from 'axios'
import { productObject } from '@/models/products'
const _ = require('lodash')

// eslint-disable-next-line no-unused-vars
export async function newProductAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/products/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getProductsList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/search/products/filter/'
    url = params !== null ? `${url}?${params}` : url
    state.selectedProduct = _.cloneDeep(productObject)
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}


// eslint-disable-next-line no-unused-vars
export async function getProductsById({ state }, id) {
  return new Promise((resolve, reject) => {
    let url = `/products/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateProductAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/products/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateAvailableProductAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/products/update-available/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function saveProductPhotos({ commit }, formData) {
  return new Promise((resolve, reject) => {
    let url = `/products-photos/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          commit('setLoadingPercentage', percentCompleted, { root: true })
        },
      })
      .then((response) => {
        resolve(response.data)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
      .catch((error) => {
        reject(error)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
  })
}

export async function saveProductVideo({ commit }, formData) {
  commit(
    'setVideoLoading',
    { videoLoading: true, loadingTextVideo: 'Subiendo video' },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/products-video/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          commit('setLoadingPercentage', percentCompleted, { root: true })
        },
      })
      .then((response) => {
        resolve(response.data)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
      .catch((error) => {
        reject(error)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
  })
}

export async function updateProductVideo({ commit }, { formData, id }) {
  commit(
    'setVideoLoading',
    { videoLoading: true, loadingTextVideo: 'Actualizando video' },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/products-video/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          commit('setLoadingPercentage', percentCompleted, { root: true })
        },
      })
      .then((response) => {
        resolve(response.data)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
      .catch((error) => {
        reject(error)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
  })
}
// eslint-disable-next-line no-unused-vars
export async function saveAdditionalInformation({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/products-additional-information/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function validateSkuCodeAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/products/validate-sku/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//listar productos relacionados
// eslint-disable-next-line no-unused-vars
export async function getProductsReleatedList({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = '/products-related/get-products/?product_id=' + id
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//Crear productos relacionados al producto
// eslint-disable-next-line no-unused-vars
export async function newProductReleated({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/products-related/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//actualiza productos relacionados al producto
// eslint-disable-next-line no-unused-vars
export async function updateProductReleated({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .put('/products-related/update-related/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function saveDigitalProduct({ commit }, formData) {
  commit(
    'setVideoLoading',
    { videoLoading: true, loadingTextVideo: 'Subiendo archivo' },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/digital-product/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          commit('setLoadingPercentage', percentCompleted, { root: true })
        },
      })
      .then((response) => {
        resolve(response.data)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
      .catch((error) => {
        reject(error)
        commit('setVideoLoading', false, { root: true })
        commit('setLoadingPercentage', 0, { root: true })
        commit('setLoadingTextVideo', '', { root: true })
      })
  })
}

//listar productos relacionados
// eslint-disable-next-line no-unused-vars
export async function getDigitalProduct({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = `/digital-product/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//listar paquetes del producto
// eslint-disable-next-line no-unused-vars
export async function getProductPacks({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/?product=${id}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function createProductPack({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando nuevo paquete del producto',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateProductPack({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando paquete del producto',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function desactivatePack({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Eliminando paquete-producto',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/${id}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function allPacks({ commit }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cargando paquetes',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function copyProductPack({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Copiando paquetes del producto',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/copy-product/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function copyPackAll({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Copiando paquetes para todos los productos',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/copy-all/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function calculatePacks({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Calculando numero total de paquetes a enviar',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/product-packaging/calculate-packs/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
