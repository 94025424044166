const ServicePay = () =>
  import(
    /* webpackChunkName: "sales" */ '@/modules/servicepay/views/servicepay.vue'
  )
const History = () => import('@/modules/servicepay/views/history.vue')
const NotifyPayment = () =>
  import('@/modules/servicepay/views/notifyPayment.vue')

const NotifyHistory = () =>
  import('@/modules/servicepay/views/notifyHistory.vue')

export default [
  {
    path: '/servicios/pago',
    name: 'servicePay',
    meta: {
      title: 'Pago de Servicios y Recargas',
      rule: 'seller',
    },
    component: ServicePay,
  },
  {
    path: '/servicios/historial',
    name: 'History',
    meta: {
      title: 'Historial de Pago de Servicios y Recargas',
      rule: 'seller',
    },
    component: History,
  },
  {
    path: '/servicios/notificar',
    name: 'Notify',
    meta: {
      title: 'Notificar Pago',
      rule: 'seller',
    },
    component: NotifyPayment,
  },
  {
    path: '/servicios/notificar-historial',
    name: 'NotifyHistory',
    meta: {
      title: 'Historial Notificaciones Pago',
      rule: 'seller',
    },
    component: NotifyHistory,
  },
]
