const NewProductView = () =>
  import(
    /* webpackChunkName: "products" */ '@/modules/products/views/NewProductsView.vue'
  )
const ProductosView = () => import('@/modules/products/views/ProductsView.vue')

const UpdateProductosView = () =>
  import('@/modules/products/views/UpdateProductView.vue')

const PackProductosView = () =>
  import('@/modules/products/views/ProductsPack.vue')

export default [
  {
    path: '/productos',
    name: 'productList',
    meta: {
      title: 'Lista de productos / servicios',
      rule: 'seller',
    },
    component: ProductosView,
  },
  {
    path: '/productos/nuevo',
    name: 'newProduct',
    meta: {
      title: 'Nuevo producto / servicio',
      rule: 'seller',
    },
    component: NewProductView,
  },
  {
    path: '/productos/:id',
    name: 'updateProduct',
    meta: {
      title: 'Editar producto / servicio',
      rule: 'seller',
    },
    component: UpdateProductosView,
  },
  {
    path: '/paquetes-producto/:id',
    name: 'productPack',
    meta: {
      title: 'Editar paquetes del producto',
      rule: 'seller',
    },
    component: PackProductosView,
  },
]
