export default {
  shopList: [],
  totalShopPages: 0,
  searchParams: '',
  detail: false,
  packages: [],
  types: [],
  additionalInformation: [],
  titleCard: '',
  typeSubmit: '',
  cardNewItemActive: false,
  cardListActive: false,
  selectedShop: {},
}
