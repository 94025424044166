const CouponView = () =>
  import('@/modules/coupons/views/CouponView.vue')

const CreateCuponView = () => import('@/modules/coupons/views/CreateCuponView.vue')

const ListCuponsView = () =>
  import('@/modules/coupons/views/ListCouponsView.vue')

export default [
  {
    path: '/cupones',
    name: 'Cupones',
    meta: {
      title: 'Cupones',
      rule: 'manager',
    },
    component: ListCuponsView,
  },
  {
    path: '/nuevo-cupon',
    name: 'newCupon',
    meta: {
      title: 'Nuevo Cupón',
      rule: 'manager',
    },
    component: CreateCuponView,
  },
  {
    path: '/coupon/:id',
    name: 'Coupon',
    meta: {
      title: 'Cupón',
      rule: 'manager',
    },
    component: CouponView,
  },
]
