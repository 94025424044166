const NewShopView = () =>
  import(/* webpackChunkName: "system" */ '@/modules/system/views/newShop.vue')

const ShopListView = () =>
  import(/* webpackChunkName: "system" */ '@/modules/system/views/shopList.vue')

const ShopDetailView = () =>
  import(
    /* webpackChunkName: "system" */ '@/modules/system/views/ShopDetail.vue'
  )
const GlobalCommissionsView = () =>
  import(
    /* webpackChunkName: "system" */ '@/modules/system/views/globalCommissions.vue'
  )

const CatalogsAdminView = () =>
  import(
    /* webpackChunkName: "system" */ '@/modules/system/views/CatalogsAdminView.vue'
  )
const PrivacyNoticeView = () =>
  import(
    /* webpackChunkName: "system" */ '@/modules/system/views/PrivacyNoticeView.vue'
  )

const AnalyticsView = () =>
  import(
    /* webpackChunkName: "system" */ '@/modules/system/views/AnalyticsView.vue'
  )

const NotifiactionsView = () =>
  import(
    /* webpackChunkName: "system" */ '@/modules/system/views/NotificationsView.vue'
  )
export default [
  {
    path: '/sistema/nueva-tienda',
    name: 'newShop',
    meta: {
      title: 'Nueva Tienda',
      rule: 'admin',
    },
    component: NewShopView,
  },
  {
    path: '/sistema/administracion-de-tiendas',
    name: 'shopList',
    meta: {
      title: 'Administración de tiendas',
      rule: 'admin',
    },
    component: ShopListView,
  },
  {
    path: '/sistema/administracion-de-tiendas/:slug',
    name: 'shopDetail',
    meta: {
      title: 'Editar tienda',
      rule: 'manager',
    },
    component: ShopDetailView,
  },
  {
    path: '/sistema/comisiones-globales',
    name: 'globalCommissions',
    meta: {
      title: 'Comisiones Globales',
      rule: 'admin',
    },
    component: GlobalCommissionsView,
  },
  {
    path: '/sistema/aviso-privacidad',
    name: 'PrivacyNoticeView',
    meta: {
      title: 'Aviso de Privaciadad',
      rule: 'admin',
    },
    component: PrivacyNoticeView,
  },
  {
    path: '/sistema/catalogo-admin',
    name: 'catalogsAdmin',
    meta: {
      title: 'Catálogo de administradores',
      rule: 'admin',
    },
    component: CatalogsAdminView,
  },
  {
    path: '/sistema/analytics',
    name: 'Analytics',
    meta: {
      title: 'Analytics',
      rule: 'admin',
    },
    component: AnalyticsView,
  },
  {
    path: '/sistema/notificaciones',
    name: 'Notifications',
    meta: {
      title: 'Notificaciones',
      rule: 'admin',
    },
    component: NotifiactionsView,
  },
]
