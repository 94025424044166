export default {
  sales: [],
  products: [],
  day: '-',
  dayF: '-',
  salesSearchParams: '',
  searchIdate: '',
  searchFdate: '',
  rulesMsi: '',
}
