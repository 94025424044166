const SupplierList = () => import('@/modules/suppliers/views/SupplierList')
const SupplierUpdate = () => import('@/modules/suppliers/views/SupplierUpdate')
const SupplierNew = () => import('@/modules/suppliers/views/SupplierNew')

export default [
  {
    path: '/proveedores',
    name: 'SupplierList',
    meta: {
      title: 'Administración de los Proveedores / Clientes',
      rule: 'manager',
    },
    component: SupplierList,
  },
  {
    path: '/proveedores/nuevo/',
    name: 'SupplierNew',
    meta: {
      title: 'Nuevo Proveedor / Ciente',
      rule: 'manager',
    },
    component: SupplierNew,
  },
  {
    path: '/proveedores/:id/',
    name: 'SupplierUpdate',
    meta: {
      title: 'Actualizar Proveedor / Ciente',
      rule: 'manager',
    },
    component: SupplierUpdate,
  },
]
