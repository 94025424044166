export default {
  newsletterClientsList: [],
  newsletterClientsTotalPages: 0,
  newsletterClientsSearchParams: '',
  newsletterClientsRows: [],
  newsletterConfiguration: {
    id: null,
    shop: null,
    newsletterBannerColor: '',
    newsletterBannerText: '',
    newsletterBannerTextColor: '',
    newsletterBannerButtonText: '',
    newsletterBannerPosition: '',
    showNameField: null,
    showFirstLastNameField: null,
    showSecondLastNameField: null,
    showPhoneField: null,
    showBirthdayField: null,
    showSexField: null,
  },
}
