import { couponObject } from '@/models/cupons'
const _ = require('lodash')

export default {
  newCoupon: _.cloneDeep(couponObject),
  updateCoupon: _.cloneDeep(couponObject),
  selectedCoupon: _.cloneDeep(couponObject),
  newCouponFormsProgress: {
    generalInfo: {
      couponData: false,
    },
    atributes: {
      atributesData: false,
    },
    adicional: {
      adicionalData: false,
    },
    quickSelection: {
      quickSelectionData: false,
    },
  },
  couponPhotos: new FormData(),
  couponsList: [],
  searchParams: '',
  totalCouponsPages: 1,
  fileVideo: new File([''], 'filename'),
  relatedCupons: [],
  couponPacks: [],
}
