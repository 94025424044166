const LoginView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/LoginView.vue')
const RegisterView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/RegisterView.vue')
const RestorePasswordEmailView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/RestorePasswordEmailView.vue'
  )
const ActivateAccount = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/ActivateAccount.vue'
  )
const ShopNameRecoveryView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/ShopNameRecoveryView'
  )
const RestorePasswordView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/RestorePasswordView.vue'
  )
const NewPasswordView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/NewPasswordView.vue'
  )

export default [
  {
    path: '/auth/login',
    name: 'authLogin',
    meta: {
      title: 'Iniciar Sesión',
      rule: 'public',
    },
    component: LoginView,
  },
  {
    path: '/auth/signup',
    name: 'authRegister',
    meta: {
      title: 'Registro',
      rule: 'public',
    },
    component: RegisterView,
  },
  {
    path: '/password-recovery',
    name: 'passwordRecovery',
    meta: {
      title: 'Recuperar contraseña',
      rule: 'public',
    },
    component: RestorePasswordEmailView,
  },
  {
    path: '/email-account-activation',
    name: 'emailAccountActivation',
    meta: {
      title: 'Activar cuenta',
      rule: 'public',
    },
    component: ActivateAccount,
  },
  {
    path: '/shop-name-recovery',
    name: 'shopNameRecovery',
    meta: {
      title: 'Recuperar Nombre de Tienda',
      rule: 'public',
    },
    component: ShopNameRecoveryView,
  },
  {
    path: '/password_reset',
    name: 'passwordReset',
    meta: {
      title: 'Coloque sus nueva contraseña',
      rule: 'public',
    },
    component: RestorePasswordView,
  },
  {
    path: '/password_new_user',
    name: 'passwordNewUser',
    meta: {
      title: 'Coloque su contraseña',
      rule: 'public',
    },
    component: NewPasswordView,
  },
]
