export default {
  titleCard: '',
  typeSubmit: '',
  cardNewItemActive: false,
  cardListActive: false,
  //---------------------
  selectedRule: {},
  ruleList: {},
  totalShopPages: 0,
  searchParams: '',
  states: {},
  rulesIds: [],
  packaging: {},
}
