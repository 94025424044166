export default {
  setSelectedShop: (state, selectedShop) => {
    state.selectedShop = selectedShop
  },
  setShopList: (state, shopList) => {
    state.shopList = shopList
  },
  setTotalShopPages: (state, totalShopPages) => {
    state.totalShopPages = totalShopPages
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setDetail: (state, detail) => {
    state.detail = detail
  },
  setPackages: (state, packages) => {
    state.packages = packages
  },
  setAdditionalInformation: (state, additionalInformation) => {
    state.additionalInformation = additionalInformation
  },
  setTypes: (state, types) => {
    state.types = types
  },
  //--------------------
  updateCardListActive: (state, status) => {
    state.cardListActive = status
  },
  updateCardNewItemActive: (state, status) => {
    state.cardNewItemActive = status
  },
  updateTitleCard: (state, title) => {
    state.titleCard = title
  },
  updateTypeSubmit: (state, type) => {
    state.typeSubmit = type
  },
}
