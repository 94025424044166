const GeneralInventoryView = () => import('@/modules/inventory/views/GeneralInventoryView.vue')
const StockMovements = () => import('@/modules/inventory/components/StockMovements.vue')

export default [
    {
        path: '/inventory/general',
        name: 'generalInventory',
        meta: {
            title: 'Inventario General',
            rule: 'manager',
        },
        component: GeneralInventoryView,

    },
    {
        path: '/inventory/stock-movements',
        name: 'stockMovements',
        meta: {
            title: 'Movimientos de stock',
            rule: 'manager',
        },
        component: StockMovements,

    },
]