import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth/store'
import shop from '@/modules/shop/store'
import catalogs from '@/modules/catalogs/store'
import products from '@/modules/products/store'
import users from '@/modules/users/store'
import marketing from '@/modules/marketing/store'
import categories from '@/modules/categories/store'
import lots from '@/modules/lot/store'
import sales from '@/modules/sales/store'
import shipping from '@/modules/shipping/store'
import system from '@/modules/system/store'
import suppliers from '@/modules/suppliers/store'
import clients from '@/modules/clients/store'
import servicepay from '@/modules/servicepay/store'
import coupons from '@/modules/coupons/store'
import custom_html from '@/modules/custom_html/store'
import inventory from '@/modules/inventory/store'

import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth'],
})
export default new Vuex.Store({
  state: {
    videoLoading: false,
    loadingPercentage: 0,
    activeLoading: false,
    loadingPageMsg: '',
    loadingTextVideo: '',
    showNavbar: true,
    errorMsg: '',
    skeletonLoading: true,
  },
  mutations: {
    setLoading(state, { isLoading, msg }) {
      state.activeLoading = isLoading
      state.loadingPageMsg = msg
    },
    setVideoLoading(state, { videoLoading, loadingTextVideo }) {
      state.videoLoading = videoLoading
      state.loadingTextVideo = loadingTextVideo
    },
    setLoadingPercentage(state, loadingPercentage) {
      state.loadingPercentage = loadingPercentage
    },
    setLoadingTextVideo(state, loadingTextVideo) {
      state.loadingTextVideo, loadingTextVideo
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg
    },
    setSkeletonLoading: (state, isLoading) => {
      state.skeletonLoading = isLoading
    },
    setShowNavbar(state, showNavbar) {
      state.showNavbar = showNavbar
    },
  },
  modules: {
    auth,
    shop,
    catalogs,
    products,
    users,
    marketing,
    categories,
    lots,
    sales,
    shipping,
    system,
    suppliers,
    clients,
    servicepay,
    coupons,
    custom_html,
    inventory
  },
  plugins: [vuexLocal.plugin],
})
