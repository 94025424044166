import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '../router'
// import { mapState, mapGetters } from 'vuex'

Vue.use(AclInstaller)

let initialRole = 'public'
let vuex = JSON.parse(localStorage.getItem('vuex'))
if (vuex && vuex.auth.user.userprofile.role)
  initialRole = vuex.auth.user.userprofile.role

export default new AclCreate({
  initial: initialRole,
  notfound: '/auth/login',
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule('admin').generate(),
    manager: new AclRule('manager').or('admin').generate(),
    seller: new AclRule('seller').or('manager').or('admin').generate(),
    public: new AclRule('public')
      .or('seller')
      .or('manager')
      .or('admin')
      .generate(),
  },
})
