import { productObject } from '@/models/products'
const _ = require('lodash')

export default {
  setNewCouponData: (state, newCoupon) => {
    state.newCoupon = _.merge(state.newCoupon, newCoupon)
  },
  clearNewCouponData: (state) => {
    state.newCoupon = _.cloneDeep(productObject)
    state.newCouponFormsProgress = {
      generalInfo: {
        productData: false,
      },
      atributes: {
        atributesData: false,
      },
      adicional: {
        adicionalData: false,
      },
      quickSelection: {
        quickSelectionData: false,
      },
      variants: {
        variantsData: false,
      },
    }
  },
  setSelectedCoupon: (state, selectedCoupon) => {
    state.selectedCoupon = selectedCoupon
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setCouponsList: (state, couponsList) => {
    state.couponsList = couponsList
    console.log(couponsList);
  },
  setTotalCouponsPages: (state, pages) => {
    state.totalCouponsPages = pages
  },
  setNewCouponProgress: (state, { section, card, status }) => {
    state.newCouponFormsProgress[section][card] = status
  },
  setUpdateCouponData: (state, updateCoupon) => {
    state.updateCoupon = _.merge(state.updateCoupon, updateCoupon)
  },
  clearUpdateCouponData: (state) => {
    state.updateCoupon = _.cloneDeep(productObject)
  },
  setCouponPhotos: (state, formData) => {
    state.productPhotos = formData
  },
  setUpdateCouponFromServer: (state, product) => {
    let updateCoupon = _.cloneDeep(product)
    // supplier
    updateCoupon.supplierId = updateCoupon.supplier
      ? updateCoupon.supplier.id
      : 0
    delete updateCoupon.supplier

    //measurementUnit
    updateCoupon.measurementUnitId = updateCoupon.measurementUnit
      ? updateCoupon.measurementUnit.id
      : 0
    delete updateCoupon.measurementUnit

    //measurementUnit
    updateCoupon.ivaId = updateCoupon.iva ? updateCoupon.iva.id : 0
    delete updateCoupon.iva

    //categories
    updateCoupon.categoriesId = updateCoupon.categories.map((item) => item.id)
    delete updateCoupon.categories

    //labels
    updateCoupon.labelsId = updateCoupon.labels.map((item) => item.id)
    delete updateCoupon.labels

    // products
    updateCoupon.placeOfOriginId = updateCoupon.placeOfOrigin.map(
      (item) => item.id
    )
    delete updateCoupon.placeOfOrigin

    updateCoupon.quickSelectionAdd = updateCoupon.quickSelection
    delete updateCoupon.quickSelection

    updateCoupon.originalVariantList = _.cloneDeep(updateCoupon.variantList)

    updateCoupon.productDetailAdd = updateCoupon.productDetail
    delete updateCoupon.productDetail

    updateCoupon.productDetailAdd.forEach((product, index) => {
      product.openCard = false
      product.saved = true
      product.warningSku = false
      product.warningCode = false
      product.promotionDetailPromotionAdd = product.promotionDetailPromotion
      product.promotionDetailPromotionAdd.promotionPerPrice = ''
      product.promotionDetailPromotionAdd.promotionMonPrice = ''
      delete product.promotionDetailPromotion
      product['idAlternative'] = product.id
      product.productDetailVariantValueAdd = product.productDetailVariantValue
      product.productDetailVariantValueAdd.map((variant) => {
        variant['idAlternative'] = variant.id
        delete variant.id
        return variant
      })
      delete product.productDetailVariantValue
      product.optionCopy = index != 0
      product.photosAdd = []
      if (product.photos['0'] === undefined) {
        const aux = {
          id: null,
          photo: '',
          photoCompress: '',
          priority: '',
        }
        product.photos['0'] = aux
        product.currentFoto = null
      } else {
        product.photosAdd.push(product.photos['0'].id)
        product.currentFoto = product.photos['0'].photoCompress
      }
      if (product.photos['1'] === undefined) {
        const aux = {
          id: null,
          photo: '',
          photoCompress: '',
          priority: '',
        }
        product.photos['1'] = aux
      } else {
        product.photosAdd.push(product.photos['1'].id)
      }
      if (product.photos['2'] === undefined) {
        const aux = {
          id: null,
          photo: '',
          photoCompress: '',
          priority: '',
        }
        product.photos['2'] = aux
      } else {
        product.photosAdd.push(product.photos['2'].id)
      }
      if (product.photos['3'] === undefined) {
        const aux = {
          id: null,
          photo: '',
          photoCompress: '',
          priority: '',
        }
        product.photos['3'] = aux
      } else {
        product.photosAdd.push(product.photos['3'].id)
      }
      if (product.photos['4'] === undefined) {
        const aux = {
          id: null,
          photo: '',
          photoCompress: '',
          priority: '',
        }
        product.photos['4'] = aux
      } else {
        product.photosAdd.push(product.photos['4'].id)
      }
    })

    state.updateCoupon = _.cloneDeep(updateCoupon)
  },
  setNewVideo: (state, newVideo) => {
    state.newVideo = newVideo
  },
  setFileVideo: (state, fileVideo) => {
    state.fileVideo = fileVideo
  },
  setRelatedCoupons: (state, relatedCoupons) => {
    state.relatedCoupons = relatedCoupons
  },
  setCouponPacks: (state, productPacks) => {
    state.productPacks = productPacks
  },
}
