export default {
  setUsers: (state, users) => {
    state.users = users
  },
  setShop: (state, shop) => {
    state.shop = shop
  },
  setSite: (state, site) => {
    state.site = site
  },
  setshopLogos: (state, shopLogos) => {
    state.shopLogos = shopLogos
  },
  setNewVideoPrincipal: (state, newVideoPrincipal) => {
    state.newVideoPrincipal = newVideoPrincipal
  },
  setFileVideoPrincipal: (state, fileVideoPrincipal) => {
    state.fileVideoPrincipal = fileVideoPrincipal
  },
  setNewVideoPrincipalMobile: (state, newVideoPrincipalMobile) => {
    state.newVideoPrincipalMobile = newVideoPrincipalMobile
  },
  setFileVideoPrincipalMobile: (state, fileVideoPrincipalMobile) => {
    state.fileVideoPrincipalMobile = fileVideoPrincipalMobile
  },
  setFileVideoIntermediate: (state, fileVideoIntermediate) => {
    state.fileVideoIntermediate = fileVideoIntermediate
  },
  setFileVideoIntermediateMobile: (state, fileVideoIntermediateMobile) => {
    state.fileVideoIntermediateMobile = fileVideoIntermediateMobile
  },
  setNewVideoIntermediate: (state, newVideoIntermediate) => {
    state.newVideoIntermediate = newVideoIntermediate
  },
  setNewVideoIntermediateMobile: (state, newVideoIntermediateMobile) => {
    state.newVideoIntermediateMobile = newVideoIntermediateMobile
  },
  setShopGallery: (state, shopGallery) => {
    state.shopGallery = shopGallery
  },
  setSocialNetworks: (state, socialNetworks) => {
    state.socialNetworks = socialNetworks
  },
  setShopType: (state, shopType) => {
    state.shopType = shopType
  },
  setBilling: (state, billing) => {
    state.billing = billing
  },
  setSchedule: (state, schedule) => {
    state.schedule = schedule
  },
  settotalBranchPages: (state, totalBranchPages) => {
    state.totalBranchPages = totalBranchPages
  },
  setbranchList: (state, branchList) => {
    state.branchList = branchList
  },
  setSelectBranch: (state, selectBranch) => {
    state.selectBranch = selectBranch
  },
  setnewBranch: (state, newBranch) => {
    state.newBranch = newBranch
  },
  setMapa: (state, mapa) => {
    state.mapa = mapa
  },
  setHorario: (state, horario) => {
    state.horario = horario
  },
  setSucursal: (state, sucursal) => {
    state.sucursal = sucursal
  },
  setNewMap: (state, newMap) => {
    state.newMap = newMap
  },
  setCreateMap: (state, createMap) => {
    state.createMap = createMap
  },
  setPromotions: (state, promotions) => {
    state.promotions = promotions
  },
  setSectionList: (state, sectionList) => {
    state.sectionList = sectionList
  },
  setSelectedSection: (state, selectedSection) => {
    state.selectedSection = selectedSection
  },
  setCreateSection: (state, createSection) => {
    state.createSection = createSection
  },
  setStripeAccount: (state, stripeAccount) => {
    state.stripeAccount = stripeAccount
  },
  setMainBranch: (state, mainBranch) => {
    state.mainBranch = mainBranch
  },
}
