const NewSaleView = () => import(/* webpackChunkName: "sales" */ '@/modules/sales/views/newSale.vue')

const SalesView = () => import(/* webpackChunkName: "sales" */ '@/modules/sales/views/sales.vue')

const AnalyticsSalesView = () => import(/* webpackChunkName: "sales" */ '@/modules/sales/views/analyticsSales.vue')
const MsiRulesView = () => import(/* webpackChunkName: "sales" */ '@/modules/sales/views/msiRules.vue')
const ReturnView = () => import(/* webpackChunkName: "sales" */ '@/modules/sales/views/generateReturn.vue')

export default [
  {
    path: '/venta/nuevo',
    name: 'newSale',
    meta: {
      title: 'Nueva Venta',
      rule: 'seller',
    },
    component: NewSaleView,
  },
  {
    path: '/venta/registros',
    name: 'sales',
    meta: {
      title: 'Ventas',
      rule: 'seller',
    },
    component: SalesView,
  },
  {
    path: '/venta/analytics',
    name: 'salesAnalytics',
    meta: {
      title: 'Analytics',
      rule: 'manager',
    },
    component: AnalyticsSalesView,
  },
  {
    path: '/devolucion/nuevo',
    name: 'generateReturn',
    meta: {
      title: 'Generar Devolución',
      rule: 'manager',
    },
    component: ReturnView,
  },
  {
    path: '/ventas/meses-sin-intereses',
    name: 'MsiRules',
    meta: {
      title: 'Meses sin intereses',
      rule: 'manager',
    },
    component: MsiRulesView,
  },
]
