import axios from 'axios'

/**
 * Obtiene el árbol de categorías.
 *
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getCategoriesTree({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = `/categories/tree-categories/?shop=${id}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene lista de categorías.
 *
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getCategoriesList({ commit }) {
  return new Promise((resolve, reject) => {
    let url = `/categories/?parent__isnull=true`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene una categoría.
 *
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getCategorie({ commit }, categorieId) {
  return new Promise((resolve, reject) => {
    let url = `/categories/${categorieId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene el lista de subcategorías.
 *
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getSubCategoriesList({ commit }, parent, page) {
  return new Promise((resolve, reject) => {
    let url = `/categories/?parent=${parent}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite eliminar una cita de la base de datos
 * @param {Number} id Identificar de la cita que se eliminará
 */
export async function deleteCategorieAction({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Eliminando categoria...',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    let url = `/categories/${id}/`
    axios
      .delete(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function createCategorie({ commit }, { formData }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Creando Categoria',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/categories/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateCategorie({ commit }, { formData, categorieId }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando Categoria',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/categories/${categorieId}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
