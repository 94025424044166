import Vue from 'vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'

Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#2072B9',
      success: '#388e3c',
      danger: '#d32f2f',
      warning: '#ff6f00',
      dark: '#573373',
    },
  },
})
