export default {
  setNewsletterClientsList: (state, newsletterClientsList) => {
    let rows = newsletterClientsList.map(function (dict) {
      let subscriptionDate = new Date(dict.subscriptionDate)
      let data = {
        email: dict.email,
        subscriptionDate: `${subscriptionDate.getDate()}-${subscriptionDate.getMonth()}-${subscriptionDate.getFullYear()}`,
      }
      let name = ''
      if (dict['name']) {
        name += dict['name'] + ' '
      }
      if (dict['lastName']) {
        name += dict['lastName'] + ' '
      }
      if (dict['secondLastName']) {
        name += dict['secondLastName'] + ' '
      }
      data.name = name
      if (dict['birthday']) {
        data.birthday = dict['birthday']
      }
      if (dict['phone']) {
        data.phone = dict['phone']
      }
      if (dict['sex']) {
        if (dict['sex'] === 'male') {
          data.sex = 'Masculino'
        } else if (dict['sex'] === 'female') {
          data.sex = 'Femenino'
        } else {
          data.sex = 'Otro'
        }
      }
      if (dict['isActive']) {
        data.active = 'Si'
      } else {
        data.active = 'No'
      }
      return data
    })
    state.newsletterClientsList = newsletterClientsList
    state.newsletterClientsRows = rows
    // Add rows
  },
  setNewsletterClientsTotalPages: (state, newsletterClientsTotalPages) => {
    state.newsletterClientsTotalPages = newsletterClientsTotalPages
  },
  setNewsletterClientsSearchParams: (state, newsletterClientsSearchParams) => {
    state.newsletterClientsSearchParams = newsletterClientsSearchParams
  },
  setNewsletterConfiguration: (state, newsletterConfiguration) => {
    state.newsletterConfiguration = newsletterConfiguration
  },
}
