export default {
  updateCardListActive: (state, status) => {
    state.cardListActive = status
  },
  updateCardNewItemActive: (state, status) => {
    state.cardNewItemActive = status
  },
  updateTitleCard: (state, title) => {
    state.titleCard = title
  },
  updateTypeSubmit: (state, type) => {
    state.typeSubmit = type
  },
  updateIvaLimit: (state, iva) => {
    state.ivaLimit = iva
  },
}
