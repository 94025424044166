export default{
    setInventoryProductsList: (state, productsList) => {
        state.inventoryProductsList = productsList
      },
    setStockMovements: (state, stockMovements) => {
        state.stockMovements = stockMovements
      },
    setProductQuantity: (state, product) =>{
      state.inventoryProductsList.forEach(obj => {
        if(Array.isArray(obj.detail)){
          obj.detail.forEach(det => {
            det.quantity = product.find(item => item.id === det.id)?.quantity || det.quantity
          })
        }
        else{
          obj.detail.quantity = product.find(item => item.id === obj.detail.id)?.quantity || obj.detail.quantity
        } 
      })
    }
}

