export default {
  users: [],
  emailError: false,
  shop: {
    id: null,
    name: '',
    slug: '',
    picture: '#',
    email: '',
    phone: '',
    address: '',
    slogan: '',
    numberOfCategories: '',
    shopTypeId: '',
    catalog: null,
    domain: '',
    packages: {
      hasSalesModule: false,
      branchOfficesLimit: 1,
      hasServicesPaymentModule: false,
      hasDomainSettingsModule: false,
      hasEmailSettingsModule: false,
      name: '',
      alias: '',
      productsLimit: 1,
    },
    currency: 'MXN',
  },
  site: '',
  shopLogos: {
    banner: '',
    bannerTextColor: '',
    ico: '',
    logo: '',
    footer: '',
    heightBanner: '',
    heightFooter: '',
    bannerSecondary: '',
    heightBannerSecondary: '',
    bannerIntermediate: '',
    heightBannerIntermediate: '',
    bannerFinal: '',
    heightBannerFinal: '',
  },
  newVideoPrincipal: false,
  newVideoPrincipalMobile: false,
  newVideoIntermediate: false,
  newVideoIntermediateMobile: false,
  fileVideoPrincipal: new File([''], 'filename'),
  fileVideoPrincipalMobile: new File([''], 'filenameMobile'),
  fileVideoIntermediate: new File([''], 'filenameIntermediate'),
  fileVideoIntermediateMobile: new File([''], 'filenameIntermediateMobile'),
  shopGallery: {},
  socialNetworks: {
    facebookUrl: '',
    instagramUrl: '',
    webUrl: '',
    twitterUrl: '',
    whatsappNumber: '',
    pinterestUrl: '',
    youtubeUrl: '',
  },
  shopType: [],
  schedule: [{}],
  billing: {
    urlForm: '',
  },
  mainBranch: false,
  branchList: [],
  totalBranchPages: 3,
  selectBranch: {
    id: null,
    name: '',
    email: '',
    phone: '',
    address: '',
    latitude: null,
    longitude: null,
    main: false,
  },
  newBranch: {
    id: null,
    name: '',
    email: '',
    phone: '',
    address: '',
    latitude: null,
    longitude: null,
  },
  mapa: false,
  horario: false,
  sucursal: null,
  newMap: false,
  createMap: true,
  promotions: {
    id: null,
    promotion_text: '',
  },
  sectionList: [],
  selectedSection: {
    id: null,
    title: '',
    bodyText: '',
    shop: null,
    featured: false,
    icon: '',
  },
  createSection: false,
  stripeAccount: {
    linkedAccount: false,
    chargersEnabled: false,
    email: '',
    disabledReason: '',
  },
}
