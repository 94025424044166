import { baseUser } from '@/models/users'

export default {
  setUserTokens: (state, { tokenAccess, tokenRefresh }) => {
    state.tokenAccess = tokenAccess
    state.tokenRefresh = tokenRefresh
  },
  setTokenAccess: (state, tokenAccess) => {
    state.tokenAccess = tokenAccess
  },
  clearTokens: (state) => {
    state.tokenAccess = null
    state.tokenRefresh = null
  },
  setUser: (state, user) => {
    state.user = user
  },
  logout: (state) => {
    state.user = baseUser
    state.tokenAccess = null
    state.tokenRefresh = null
  },
}
